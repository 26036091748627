<template>
  <shift-handover-accordion-item
    title="Station Cash Collection Forms"
    :status="status"
  >
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <asom-client-table
        :data="sccfList"
        :columns="[
          'referenceNo',
          'createdBy',
          'date',
          'time',
          'dws'
        ]"
      >
        <template v-slot:referenceNo="{ rowData }">
          {{rowData.referenceNo}}
          <div v-if="!rowData.status" class="hint-draft">Draft</div>
        </template>
        <template v-slot:header_dws>Shift</template>
        <template v-slot:header_createdBy>Staff ID</template>
        <template v-slot:date="{rowData}">{{displayUtcDate(rowData.dateCreated)}}</template>
        <template v-slot:time="{rowData}">{{displayUtcTime(rowData.dateCreated)}}</template>
      </asom-client-table>
      <template v-if="!readonly">
        <asom-form-field label="Confirmation">
          <asom-input-radio-group
            horizontal
            v-model="computedAligned"
            :options="alignOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="!isAligned"
          label="Discrepancy Report"
          required
          description="Discrepancy report must be selected"
          :state="inputStates('sccfSummary.discrepancyReports')"
          error="No discrepancy report added"
        >
          <span class="pr-4">{{
            selectedDiscrepancyReports.length > 0
              ? selectedDiscrepancyReports.length + ` Selected `
              : null
          }}</span>
          <asom-button
            :text="selectedDiscrepancyReports.length > 0 ? 'Edit' : 'Add'"
            :icon="selectedDiscrepancyReports.length > 0 ? 'pen' : 'add-r'"
            icon-position="left"
            @click="addReports"
          ></asom-button>
        </asom-form-field>
        <discrepancy-report-selection
          v-model="showReportSelection"
          @selectedItems="setReports($event)"
          :selected-reports="selectedDiscrepancyReports"
        />
      </template>
      <template v-else-if="!isAligned">
        <h4 class="text-md font-semibold">Discrepancy Reports</h4>
        <asom-client-table
          :columns="[
            'no',
            'referenceNo',
            'description',
          ]"
          :data="discrepancyReports"
          :filterable="false"
          :pagination="false"
        > 
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
      </template>
    </div>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import { displayUtcDate, displayUtcTime } from '@/helpers/dateTimeHelpers'
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import DiscrepancyReportSelection from "./_DiscrepancyReportSelection.vue";
import {
  baseSummaryMixin,
  summaryDiscrepancyReportMixin,
} from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
    DiscrepancyReportSelection,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryDiscrepancyReportMixin],
  data() {
    return {
      showReportSelection: false,
      selectedDiscrepancyReports: [],
    };
  },
  computed: {
    sccfList() {
      return get(this.data, "forms", []);
    },
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    addReports() {
      this.showReportSelection = true;
    },
    setReports(reports) {
      this.selectedDiscrepancyReports = reports;
      this.computedDiscrepancyReports = reports;
    },
  },
};
</script>
